<template>
  <v-app>
    <Header />
    <v-main class="grey lighten-3">
      <banner />
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            lg="3"
            offset-lg="0"
            md="6"
            offset-md="3"
            class="hidden-md-and-down"
          >
          <v-sheet
              rounded="lg"
              min-height="350"
              min-width="225"
            >
              <book-card />
            </v-sheet>
          </v-col>

          <v-col
            cols="12"
            lg="6"
            offset-lg="0"
            md="8"
            offset-md="2"
            xs="8"
            offset-xs="2"
          >
            <router-view />
          </v-col>

          <v-col
            cols="12"
            lg="3"
            offset-lg="0"
            md="6"
            offset-md="3"
          >
            <v-sheet
              rounded="lg"
              min-height="350"
              min-width="225"
            >
              <contact-card />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
  import Footer from './components/Footer.vue'
  import Header from './components/Header.vue'
  import ContactCard from './components/ContactCard.vue'
  import BookCard from './components/BookCard.vue'
  import Banner from './components/Banner.vue'
  export default {
    components: {
      Footer,
      Header,
      ContactCard,
      BookCard,
      Banner
    },
    data (){
      return {
        cards: true
      }
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
</style>