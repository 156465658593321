<template>
    <v-container>
        <v-row>
            <v-col>
                <div class="text-center text-h2">Services</div>
            </v-col>
            <v-col col="12" sm="12">
                <v-card
                    class="mx-auto my12 mb-4"
                    max-width="750"
                    elevation="10"
                >
                    <v-img 
                        src="../assets/testimonialbg2.jpg"
                        max-height="250"
                    ></v-img>
                    <v-card-title class="accent secondary--text text-h4 justify-center mb-3">
                        <v-icon large color="secondary" class="mr-3">mdi-truck-check</v-icon>
                        MOVING
                        <v-icon large color="secondary" class="ml-3">mdi-truck-check</v-icon>
                    </v-card-title>
                  
                    <v-card-text class="text-h5 grey--text text--darken-4">
                        Axle Moving Systems provides moving services that include a free quote for homes, 
                        condos and apartments and small office/commercial moves. We take great care of the items we transport, 
                        treating them as if they were our own. We are also able to move items to and from storage as needed.
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col col="12" sm="12">
                <v-card
                    class="mx-auto my12 mb-4"
                    max-width="750"
                    elevation="10"
                >
                     <v-img 
                        src="../assets/delivery-truck.jpeg"
                        max-height="250"
                    ></v-img>
                    <v-card-title class="accent secondary--text text-h4 justify-center mb-3">
                        <v-icon large color="secondary" class="mr-3">mdi-truck-delivery</v-icon>
                        DELIVERIES
                        <v-icon large color="secondary" class="ml-3">mdi-truck-delivery</v-icon>
                    </v-card-title>
                    <v-card-text class="text-h5 grey--text text--darken-4">
                        We provide specific delivery services aimed at product delivery for Nova Scotia businesses. 
                        This includes local freight deliveries with specified delivery times and dates. We work closely with our partners 
                        to make sure that our delivery services are reliable and go the extra mile to support high customer satisfaction. 
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col col="12" sm="12">
                <v-card
                    class="mx-auto my12 mb-4"
                    max-width="750"
                    elevation="10"
                >
                    <v-img 
                        src="../assets/boxes.jpeg"
                        max-height="250"
                    ></v-img>
                    <v-card-title class="accent secondary--text text-h4 justify-center mb-3">
                        <v-icon large color="secondary" class="mr-3">mdi-package-variant-closed</v-icon>
                        PACKING
                        <v-icon large color="secondary" class="ml-3">mdi-package-variant-closed</v-icon>
                    </v-card-title>
                    <v-card-text class="text-h5 grey--text text--darken-4">
                        Moving can be a highly stressful and time sensitive thing. We aim to alleviate stress and make your move as easy on you as possible.

                        We provide packing services based on our years of experience transporting goods. We make use of industry standards, 
                        specific to the items you need moved, that help ensure your goods are transported safely and incident free. 
                        Axle Moving Systems is fully insured and you can have peace of mind knowing that we take the utmost care 
                        of our customers' possessions. 
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col col="12" sm="12">
                <v-card
                    class="mx-auto my12 mb-4"
                    max-width="750"
                    elevation="10"
                >
                    <v-img 
                        src="../assets/ns_map.jpeg"
                        max-height="250"
                    ></v-img>
                    <v-card-title class="accent secondary--text text-h4 justify-center mb-3">
                        <v-icon large color="secondary" class="mr-3">mdi-map</v-icon>
                        OUT OF TOWN
                        <v-icon large color="secondary" class="ml-3">mdi-map</v-icon>
                    </v-card-title>
                    <v-card-text class="text-h5 grey--text text--darken-4">
                        Axle Moving Systems has, unique, regularly scheduled out of town deliveries, providing our customers with an option 
                        for broader geographic moves and deliveries. On the 15th and 30th of each month, we expand our service area to include:
                    </v-card-text>
                    <v-col col="8">
                        <v-simple-table dark dense>
                            <thead>
                                <tr>
                                    <th class="text-button secondary black--text">
                                        South Shore (15th)
                                    </th>
                                    <th class="text-button secondary black--text">
                                        North Shore (30th)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="place in places"
                                    :key="place"
                                >
                                    <td>{{ place.south }}</td>
                                    <td>{{ place.north }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                    <v-card-text class="text-h5 grey--text text--darken-4">
                        Whether it’s moving to a new home outside standard HRM delivery areas, or providing your 
                        customers with a wider delivery territory, Axle Moving Systems is proud to offer out of town moves and 
                        deliveries to better serve our customers throughout Nova Scotia.
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
        places: [
            { south: 'Bridgewater', north: 'Stewiacke'},
            { south: 'Shelburne', north: 'Truro'},
            { south: 'Barrington Passage', north: 'New Glasgow'},
            { south: 'Yarmouth', north: 'Antigonish'},
            { south: 'Digby & Annapolis', north: 'Port Hawkesbury'},
            { south: 'New Minas', north: 'Sydney'}

        ]
    }),
  }
</script>
