<template>
  <v-card color="grey darken-4" elevation="20" align="center">
    <v-img src="../assets/about_pic3.jpg"></v-img>
    <v-card-title class="black secondary--text text-h5 mb-4 justify-center">
      <v-icon dark large color="secondary" class="mr-3"
        >mdi-card-account-phone-outline</v-icon
      >
      CONTACT US
    </v-card-title>
    <v-card-text class="secondary--text text-h5">
      <v-icon dark color="secondary" class="mr-1">mdi-cellphone</v-icon>
      <a href="tel:902-580-0227" class="secondary--text">902-580-0227</a>
      <br />
      <v-icon dark color="secondary" class="mr-3">mdi-email</v-icon>
      <a href="mailto:contact@axlemovingsystems.com" class="secondary--text"
        >E-mail AMS</a
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ContactCard",

  data: () => ({}),
};
</script>
