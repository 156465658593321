import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
           light: {
               primary: colors.shades.black,
               secondary: '#DBC02A',
               accent: colors.grey.darken4,
               error: colors.red.accent3,
           }
       }
    }
});
