<template>
  <div>
    <v-app-bar
        app
        color="primary"
        dark
      >
      <div class="d-flex align-center">
        <v-img
          alt="AMS Logo"
          class="shrink-mr-2"
          contain
          src="../assets/logo_name.png"
          transition="scale-transition"
          width="110"
        />
      </div>
        <v-tabs
          right
          class="ml-n4 hidden-md-and-down"
          color="secondary"
        >
          <v-tab
            class="headline"
            
            v-for="item in items"
            :key="item"
            :to="item.path"
          >
            <v-icon large class="pa-2 ma-2">{{ item.icon }}</v-icon>
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-app-bar-nav-icon 
          x-large 
          color="secondary"  
          class="hidden-lg-and-up mr-2"
          @click.stop="drawer = !drawer"
        >
        </v-app-bar-nav-icon>
    </v-app-bar>
     <v-navigation-drawer
      v-model="drawer"
      app
      dark
      right
      fixed
      temporary
      class="black"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.path"
          >
            <v-icon color="secondary" class="mr-4">{{ item.icon }}</v-icon>
            <v-list-item-title>{{ item.title.toUpperCase() }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
  export default {
    name: 'Header',

    data: () => ({
        drawer: false,
        group: null,
        hidden: true,
        items: [
            { title: 'Home', path: '/', icon: 'mdi-home'},
            { title: 'Services', path: '/services', icon: 'mdi-truck'},
            { title: 'Quote', path: '/quote', icon: 'mdi-comment-quote-outline'},
            { title: 'About', path: '/about', icon: 'mdi-information'},
      ],
    }),
    watch: {
      group () {
        this.drawer = false
      }
    }
  }
</script>

<style>
.menu {
  margin-right: 50px;
}
</style>
