<template>
    <v-container>
        <v-row>
            <v-alert 
                success 
                dark
                dismissible
                prominent 
                v-model="alert" 
                class='alerted'>
                Your message has been successfully sent.
            </v-alert>
        </v-row>
        <v-row class="text-left" justify="space-around">
            <v-col lg="12">
                <div class="text-center text-h2">
                    Free Quote
                </div>
            </v-col>
            <v-col cols="12" lg="10">
                <div class="font-weight-regular text-left text-h5">
                    Fill out the information below and we'll get back to you within 24 hours, providing a free quote and booking a 
                    convenient moving or delivery time.
                
                    Unlike many of our competitors who respond with automated emails, we'll call you directly and make sure the price 
                    and time best fit your needs.
                </div>
            </v-col>
            <v-col cols="10" align="center" sm="5">
                <v-form v-model="valid" ref="form">
                    <v-text-field
                        v-model="name"
                        name="name"
                        label="Name"
                        solo
                        prepend-icon="mdi-account"
                        :rules="nameRules"
                        :counter="30"
                        required
                    ></v-text-field>
                    <v-text-field
                        v-model="phone"
                        label="Phone"
                        name="phone"
                        solo
                        prepend-icon="mdi-cellphone"
                    ></v-text-field>
                    <v-text-field
                        v-model="email"
                        label="Email"
                        name="email"
                        solo
                        prepend-icon="mdi-email"
                        :rules="emailRules"
                        required
                    ></v-text-field>
                    <v-radio-group 
                        v-model="moveType" 
                        prepend-icon="mdi-home-city">
                        <v-radio class="ml-2" label="House" value="house"></v-radio>
                        <v-radio class="ml-2" label="Apartment/Condo" value="apartment/condo"></v-radio>
                        <v-radio class="ml-2" label="Small Business" value="small business"></v-radio>
                    </v-radio-group>
                    <v-textarea
                        v-model="other"
                        label="Other information"
                        name="other"
                        solo
                        prepend-icon="mdi-information"
                    ></v-textarea>
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        full-width
                        width="290px"
                    >
                        <v-text-field
                            slot="activator"
                            label="Pick prefered moving date"
                            solo
                            prepend-icon="mdi-calendar" 
                            v-model="date"
                            readonly
                            :value="date"
                            @click="modal = true">
                        </v-text-field>
                        <v-date-picker 
                            v-model="date"
                            elevation="10"
                            color="secondary"
                            class="black--text"
                        >
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" class="black--text" @click="modal = false">Cancel</v-btn>
                            <v-btn color="secondary" class="black--text" @click="$refs.dialog.save(date)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                    <br>
                    <v-btn 
                        x-large color="secondary" 
                        class="black--text" 
                        align="center"
                        @click="submit"
                        :class="{green: valid}">
                        Submit
                    </v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'Quote',
    data() {
        return {
            alert: false,
            valid: false,
            formSent: false,
            name: '',
            nameRules: [
                (v) => !!v || 'Name is required',
                (v) => v && v.length <= 20 || 'Name must be less than 20 characters'
            ],
            phone: '',
            email: '',
            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
            ],
            other: '',
            moveType: '',
            modal: false,
            date: null,
        }
    },
    methods: {
        submit () {
            if (this.$refs.form.validate() && !this.formSent) {
                      window.emailjs.send('mailgun', 'ams_quote', {
                    name: this.name,
                    phone: this.phone,
                    email: this.email,
                    moveType: this.moveType,
                    other: this.other,
                    date: this.date
                })
                .then(
                    function (response) {
                        console.log('SUCCESS', response)
                    },
                    function (error) {
                        console.log('FAILED', error)
                    }
                )
                this.alert = true
                this.formSent = true
                this.$emit('sent')
            }
        },
    }
}
</script>

<style>
  .alerted {
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1;
  }
</style>