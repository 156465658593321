<template>
  <v-banner elevation="15" color="grey darken-4 text-center mb-4">
    <v-img
      :src="require('../assets/logo.png')"
      class="mx-4"
      contain
      height="200"
    />
    <p class="secondary--text display-3">Axle Moving Systems</p>
    <!-- <p class="grey--text display-1 font-italic">The Most Reliable Movers in Town</p> -->
    <p class="secondary--text display-1">
      <v-icon dark large color="secondary">mdi-cellphone</v-icon>
      <a href="tel:902-580-0227" class="secondary--text">902-580-0227</a>
    </p>
  </v-banner>
</template>

<script>
export default {
  name: "Banner",

  data: () => ({}),
};
</script>
