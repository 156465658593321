<template>
  <v-container fluid>
    <v-row class="text-left" justify="space-around">
      <v-col>
        <h1 class="text-center display-3">Axle Moving Systems</h1>
      </v-col>
      <v-col cols="12" lg="10">
        <div class="text-h5 ma-2">
          We offer professional moving services with a focus in the Halifax Regional Municipality (HRM). AMS is fully insured and has
          years of moving and delivery experience. When you need fast reliable movers we're the ones to call!
        </div>
      </v-col>
      <v-col cols="12" lg="10">
        <h1 class="text-h5 ma-2">
          AMS also provides special out of town deliveries scheduled on the 15th and 30th of every month. 
          So please contact us today and reserve your delivery time. There are limited slots available.
        </h1>
      </v-col>

      <v-col
        class="mx-6"
        cols="12"
        lg="10"
      >
        <h1 class="text-h5 ma-2">
          We give free quotes so don't hesitate to call us or click below to provide us with some information on your 
          move and we'll get back to you with a direct phone call within 24 hours.
        </h1>
      </v-col>
      <v-col cols="12" lg="12" class="text-center">
        <v-btn 
          color="secondary black--text" 
          elevation="7" 
          x-large height="80"
          to="/quote"
          >
              Get Your <br>Free Quote
      </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
    }),
  }
</script>
