<template>
    <v-card
        color="grey darken-4"
        elevation="20"
        align="center"
        >
        <v-img 
            src="../assets/Banner1.jpg"
        ></v-img>
        <v-card-title class="black secondary--text text-h5 justify-center">
            <v-icon dark large color="secondary" class="mr-2">mdi-book-account-outline</v-icon>
            BOOK A MOVE
        </v-card-title>
        <v-btn 
            to="/quote"
            x-large 
            class="secondary black--text my-4">
            Book Now
        </v-btn>
    </v-card>
</template>

<script>
  export default {
    name: 'BookCard',

    data: () => ({
    }),
  }
</script>
