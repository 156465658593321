<template>
  <v-container fluid>
    <v-row class="text-left" justify="space-around">
      <v-col lg="12">
        <h1 class="text-center display-3">About Us</h1>
      </v-col>
      <v-col cols="12" lg="10">
        <div class="text-h5">
          Looking for a quality Moving Company you can depend on? Well look no 
          further. We are Axle Moving Systems, available where and when you need us.
        </div>
        <br>
        <div class="text-h5">
          Since 2015, we’ve been committed to being the best at what we do, serving 
          clients in the Halifax Regional Municipal (HRM) area by not just meeting 
          their expectations, but exceeding them. With a team that has over a decade 
          of experience in managing and providing professional moving and delivery 
          services, we are a locally owned and operating company that is committed to providing superior 
          service to our clients. 
        </div>
        <br>
        <div class="text-h5">
          We are a fully insured company that takes great pride 
          in providing timely and reliable delivery; so that we can continue to earn 
          ourselves a reputation as the most reliable movers in town.
        </div>
      </v-col>
      <v-col cols="12" lg="10">
        <div class="text-h5">
        To learn more about our specific capabilities please feel free to contact us anytime.
        </div>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn
          to="/services" 
          color="secondary" 
          class="mb-4 black--text" 
          elevation="5" 
          x-large>
          Services
        </v-btn>
      </v-col>
      <v-col cols="10">
        <div class="text-center text-h4">
          Get your free quote today.
        </div>
      </v-col>
      <v-col cols="12" align="center">
        <v-btn 
          to="/quote"
          color="secondary" 
          class="mb-4 black--text" 
          elevation="5"
          x-large>
          Free Quote
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'About',

    data: () => ({
    }),
  }
</script>

