<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-card-title class="justify-center">
        <strong class="subheading mt-1">
          <a
            href="tel:902-580-0227"
            class="white--text text-decoration-underline"
          >
            Call to schedule a move today!
          </a>
        </strong>
      </v-card-title>

      <v-card-text class="py-2 secondary--text text-center primary">
        <a href="https://e-systemsconsulting.com" class="secondary--text">
          © {{ new Date().getFullYear() }} — ESC
        </a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",

  data: () => ({}),
};
</script>
